import { useCallback, useEffect, useMemo, useState } from 'react'

import Image from 'next/image'

import ReactPlayer from 'react-player'

import { PageProps, trackVideoEvent } from '@/utils/analytics/trackVideoEvent'
import { calculateAspectRatio } from '@/utils/string/calculateAspectRatio'

import { useAnalyticsContext } from '@/components/Scripts/Segment/AnalyticsProvider'

type Props = {
	className?: string
	url?: string
	options?: WistiaVideoOptions
	aspectRatio?: string
	onHasData?: (video: WistiaVideoAPI) => void
	popover?: boolean
}

export type WistiaVideoOptions = {
	autoplay?: boolean
	controlsVisibleOnLoad?: boolean
	copyLinkAndThumbnailEnabled?: boolean
	doNotTrack?: boolean
	email?: string
	endVideoBehavior?: 'default' | 'reset' | 'loop'
	fakeFullscreen?: boolean
	fitStrategy?: 'contain' | 'cover' | 'fill' | 'none'
	fullscreenButton?: boolean
	fullscreenOnRotateToLandscape?: boolean
	keyMoments?: boolean
	muted?: boolean
	playbackRateControls?: boolean
	playbar?: boolean
	playButton?: boolean
	playerColor?: string
	playlistLinks?: string
	playlistLoop?: boolean
	playsinline?: boolean
	playPauseNotifier?: boolean
	playSuspendedOffscreen?: boolean
	plugin?: {
		'requireEmail-v1': {
			allowSkip?: boolean
			alwaysShow?: boolean
			topText?: string
			bottomText?: string
			time?: number
			askName?: boolean
			invalidDomains?: string
			validDomains?: string
			emailExampleText?: string
			firstNameExampleText?: string
			lastNameExampleText?: string
			sectionIndex?: number
			videoIndex?: number
		}
		'postRoll-v1': {
			autoSize?: boolean
			backgroundOpacity?: number
			image?: string
			link?: string
			on?: boolean
			raw?: string
			rewatch?: boolean
			text?: string
			time?: number
		}
		'midrollLink-v1': {
			links:
				| {
						time?: number
						duration?: number
						text?: string
						url?: string
				  }[]
				| false
		}
		videoThumbnail?: {
			trimStart: number
			trimEnd: number
			on?: boolean
			hashedId?: string
			async?: false
		}
		share?: {
			channels?: string
			pageTitle?: string
			overrideUrl?: boolean
			pageUrl?: string
			downloadType?: 'sd_mp4' | 'hd_mp4' | 'original'
			tweetText?: string
		}
		chapters?: {
			on?: boolean
			chapterList?: { title: string; time: number }[]
		}
		'captions-v1': {
			onByDefault?: boolean
			language?: string
			subtitlesScale?: number
			transcript?: boolean
		}
	}
	preload?: 'none' | 'metadata' | 'auto' | 'true' | 'false'
	qualityControl?: boolean
	qualityMax?: number
	qualityMin?: number
	resumable?: boolean
	seo?: boolean
	settingsControl?: boolean
	silentAutoPlay?: boolean
	smallPlayButton?: boolean
	stillUrl?: string
	time?: number | string
	thumbnailAltText?: string
	videoFoam?: boolean | { minWidth: number; maxWidth: number; minHeight: number; maxHeight: number }
	volume?: number
	volumeControl?: boolean
	wmode?: 'opaque' | 'transparent' | 'window'
}

export type WistiaVideoAPI = {
	addToPlaylist: (
		hashedId: string,
		options?: WistiaVideoOptions,
		position?: { before?: string; after?: string; index?: number }
	) => void
	aspect: () => number
	bind: (event: string, callback: () => void) => void
	cancelFullscreen: () => void
	duration: () => number
	email: (() => string | null) | ((val: string) => void)
	embedded: () => boolean
	eventKey: () => string
	getSubtitlesScale: () => number
	hasData: () => boolean
	hashedId: () => string
	height: (() => number) | ((val: number, options?: { constrain: boolean }) => void)
	inFullscreen: () => boolean
	isMuted: () => boolean
	mute: () => void
	name: () => string | null
	pause: () => void
	percentWatched: () => number
	play: () => void
	playbackRate: (rate: number) => void
	ready: () => boolean
	remove: () => void
	replaceWith: (
		hashedId: string,
		options?: WistiaVideoOptions & { transition: 'slide' | 'fade' | 'crossfade' | 'none' }
	) => void
	requestFullscreen: () => void
	revoke: () => void
	secondsWatched: () => number
	secondsWatchedVector: () => number[]
	setSubtitlesScale: (scale: number) => void
	state: () => 'beforeplay' | 'playing' | 'paused' | 'ended'
	time: (() => number) | ((val: number) => void)
	unbind: (event: string, callback: () => void) => void
	unmute: () => void
	videoHeight: (() => number) | ((val: number, options?: { constrain: boolean }) => void)
	videoQuality: (() => string) | ((val: string) => void)
	videoWidth: (() => number) | ((val: number, options?: { constrain: boolean }) => void)
	visitorKey: () => string
	volume: (() => number) | ((val: number) => void)
	width: (() => number) | ((val: number, options?: { constrain: boolean }) => void)
}

export function getWistiaIdFromURL(url: string) {
	try {
		const paths = new URL(url).pathname.replace('.jsonp', '').split('/')

		return paths[paths.indexOf('medias') + 1] || paths[paths.indexOf('iframe') + 1]
	} catch (e) {
		return null
	}
}

export function WistiaVideo({
	className,
	url,
	aspectRatio = '16:9',
	options,
	onHasData,
	popover
}: Props) {
	const id = url && getWistiaIdFromURL(url)
	const [loaded, setLoaded] = useState(false)
	const [thumbnail, setThumbnail] = useState<string | any>(null)
	const [video, setVideo] = useState<string | any>(null)
	const [videoPosition, setVideoPosition] = useState<number>(0)
	const [videoDuration, setVideoDuration] = useState<number | null>(null)
	const [pageProps, setPageProps] = useState<PageProps | null>(null)
	const { analytics } = useAnalyticsContext()

	useEffect(() => {
		async function fetchThumbnail() {
			const response = await fetch(
				`https://fast.wistia.com/oembed?url=https://fast.wistia.com/medias/${id}`
			)
			const data = await response.json()
			if (data?.thumbnail_url)
				setThumbnail({
					url: data.thumbnail_url,
					dimensions: { width: data.thumbnail_width, height: data.thumbnail_height }
				})
		}
		if (id && !thumbnail) fetchThumbnail()
	}, [thumbnail, id])

	const getPlaybackStats = () => {
		const media = video?.data?.media
		return {
			content_asset_ids: media?.hashedId,
			video_title: media?.name,
			position: videoPosition,
			total_length: videoDuration
		}
	}

	const handleOnReady = () => {
		setLoaded(true)
	}

	const handleOnDuration = (duration: number | null) => {
		setVideoDuration(duration)
	}

	useEffect(() => {
		const initWistia = async () => {
			if (window?.Wistia) {
				setVideo(window.Wistia.api(id))
			}
		}
		if (id && loaded && !video) initWistia()

		if (window?.location) {
			setPageProps({
				url: window.location.href,
				hostname: window.location.hostname,
				referrer: document.referrer,
				path: window.location.pathname,
				search: window.location.search
			})
		}
	}, [id, loaded, onHasData, video])

	const playbackStats = useMemo(() => getPlaybackStats(), [video, videoPosition, videoDuration])

	const handleOnPlay = useCallback(() => {
		const eventDetails = {
			...playbackStats,
			name: 'Video Playback Started'
		}
		trackVideoEvent(analytics, pageProps, eventDetails)
	}, [playbackStats, analytics, pageProps])

	const handleOnPause = () => {
		const eventDetails = {
			...getPlaybackStats(),
			name: 'Video Playback Paused'
		}
		trackVideoEvent(analytics, pageProps, eventDetails)
	}

	const handleOnProgress = (data: { played: number; playedSeconds: number }) => {
		const eventDetails = {
			...getPlaybackStats(),
			percentage_viewed: Math.round(data.played * 100),
			position: Math.round(data.playedSeconds),
			name: 'Video Percentage Viewed'
		}
		trackVideoEvent(analytics, pageProps, eventDetails)
		setVideoPosition(Math.round(data.playedSeconds))
	}

	const handleOnEnded = () => {
		const eventDetails = {
			...getPlaybackStats(),
			name: 'Video Playback Completed'
		}
		trackVideoEvent(analytics, pageProps, eventDetails)
	}

	const WistiaThumbnail = () => {
		return (
			<Image
				src={thumbnail?.url ?? `https://fast.wistia.com/embed/medias/${id}/swatch`}
				width={thumbnail?.dimensions.width}
				height={thumbnail?.dimensions.height}
				alt="Video Thumbnail"
				className="object-cover w-full h-full cursor-pointer"
				priority
			/>
		)
	}

	return (
		<div className={`${className}  overflow-hidden`}>
			{id ? (
				<div
					className="player-wrapper relative"
					style={{ paddingTop: `${100 / calculateAspectRatio(aspectRatio)}%` }}
				>
					{thumbnail && (
						<ReactPlayer
							light={thumbnail ? <WistiaThumbnail /> : true}
							url={`https://home.wistia.com/medias/${id}`}
							config={{
								wistia: {
									playerId: id,
									options: {
										...options,
										controlsVisibleOnLoad: true,
										plugin: {
											...options?.plugin,
											videoThumbnail: {
												...options?.plugin?.videoThumbnail,
												hashedId: id
											}
										},
										popover: popover,
										videoFoam: true
									}
								}
							}}
							className="react-player"
							controls={true}
							playing={true}
							width="100%"
							height="100%"
							style={{ position: 'absolute', top: 0, left: 0 }}
							onReady={handleOnReady}
							onDuration={(duration) => {
								handleOnDuration(duration)
							}}
							onPlay={handleOnPlay}
							onPause={handleOnPause}
							onProgress={(data) => {
								handleOnProgress(data)
							}}
							onEnded={handleOnEnded}
						/>
					)}
				</div>
			) : (
				<div className="p-5 w-full text-center text-lg bg-yellow-300 rounded-lg">
					Cannot find ID of Wistia video. The Share URL should look like &nbsp;
					<code>https://fast.wistia.com/medias/:yourID</code>
				</div>
			)}
		</div>
	)
}
