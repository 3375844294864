import dynamic from 'next/dynamic'

import { Select, Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

export const props = {
	className: Style(),
	formId: TextInput({
		label: 'Form ID',
		defaultValue: '',
		selectAll: true
	}),
	colorMode: Select({
		label: 'Color Mode',
		options: [
			{ value: 'light', label: 'Light' },
			{ value: 'dark', label: 'Dark' }
		],
		defaultValue: 'light'
	}),
	style: Select({
		label: 'Appearance',
		options: [
			{ value: 'default', label: 'Default' },
			{ value: 'underlined', label: 'Underlined' }
		],
		defaultValue: 'underlined'
	}),
	postPdfUrl: TextInput({
		label: 'Post PDF URL',
		defaultValue: '',
		selectAll: true
	}),
	postTitle: TextInput({
		label: 'Title',
		defaultValue: '',
		selectAll: true
	}),
	postUrl: TextInput({
		label: 'Post URL',
		defaultValue: '',
		selectAll: true
	}),
	submitText: TextInput({
		label: 'Submit text',
		defaultValue: 'Submit',
		selectAll: true
	}),
	color: Select({
		label: 'Submit color',
		options: [
			{ value: 'primary', label: 'Primary' },
			{ value: 'secondary', label: 'Secondary' }
		],
		defaultValue: 'primary'
	}),
	size: Select({
		label: 'Submit size',
		options: [
			{ value: 'default', label: 'Default' },
			{ value: 'large', label: 'Large' }
		],
		defaultValue: 'default'
	}),
	align: Select({
		label: 'Align submit',
		options: [
			{ value: 'left', label: 'Left' },
			{ value: 'center', label: 'Center' },
			{ value: 'right', label: 'Right' }
		],
		defaultValue: 'left'
	})
}

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./MarketoForm').then(({ MarketoForm }) => MarketoForm)))
	),
	{
		type: 'marketo-form',
		label: 'Marketo Form',
		props
	}
)
