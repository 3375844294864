import { useEffect, useState } from 'react'

import Image from 'next/image'

import ReactPlayer from 'react-player'

import { PageProps, trackVideoEvent } from '@/utils/analytics/trackVideoEvent'
import { calculateAspectRatio } from '@/utils/string/calculateAspectRatio'

import { useAnalyticsContext } from '@/components/Scripts/Segment/AnalyticsProvider'
import { WistiaVideoOptions, getWistiaIdFromURL } from '@/components/WistiaVideo'

type Props = {
	className?: string
	url?: string
	options?: WistiaVideoOptions
	aspectRatio?: string
	image?: { url: string; dimensions: { width: number; height: number } }
	imageAlt: string
}

export const WistiaImage = function WistiaImage({
	className,
	url,
	aspectRatio = '16:9',
	options,
	image,
	imageAlt
}: Props) {
	const id = url && getWistiaIdFromURL(url)
	const [loaded, setLoaded] = useState(false)
	const [video, setVideo] = useState<string | any>(null)
	const [videoPosition, setVideoPosition] = useState<number>(0)
	const [videoDuration, setVideoDuration] = useState<number | null>(null)
	const [pageProps, setPageProps] = useState<PageProps | null>(null)
	const { analytics } = useAnalyticsContext()

	const getPlaybackStats = () => {
		const media = video.data?.media
		return {
			content_asset_ids: media?.hashedId,
			video_title: media?.name,
			position: videoPosition,
			total_length: videoDuration
		}
	}

	const handleOnReady = () => {
		setLoaded(true)
	}

	const handleOnDuration = (duration: number | null) => {
		setVideoDuration(duration)
	}

	useEffect(() => {
		const initWistia = async () => {
			if (window?.Wistia) {
				setVideo(window.Wistia.api(id))
			}
		}
		if (id && loaded && !video) initWistia()

		if (window?.location) {
			setPageProps({
				url: window.location.href,
				hostname: window.location.hostname,
				referrer: document.referrer,
				path: window.location.pathname,
				search: window.location.search
			})
		}
	}, [id, loaded, video])

	const handleOnPlay = () => {
		const eventDetails = {
			...getPlaybackStats(),
			name: 'Video Playback Started'
		}
		trackVideoEvent(analytics, pageProps, eventDetails)
	}

	const handleOnPause = () => {
		const eventDetails = {
			...getPlaybackStats(),
			name: 'Video Playback Paused'
		}
		trackVideoEvent(analytics, pageProps, eventDetails)
	}

	const handleOnProgress = (data: { played: number; playedSeconds: number }) => {
		const eventDetails = {
			...getPlaybackStats(),
			percentage_viewed: Math.round(data.played * 100),
			position: Math.round(data.playedSeconds),
			name: 'Video Percentage Viewed'
		}
		trackVideoEvent(analytics, pageProps, eventDetails)
		setVideoPosition(Math.round(data.playedSeconds))
	}

	const handleOnEnded = () => {
		const eventDetails = {
			...getPlaybackStats(),
			name: 'Video Playback Completed'
		}
		trackVideoEvent(analytics, pageProps, eventDetails)
	}

	const WistiaThumbnail = () => {
		return (
			<Image
				src={image?.url ?? `https://fast.wistia.com/embed/medias/${id}/swatch`}
				width={image?.dimensions.width}
				height={image?.dimensions.height}
				alt={imageAlt}
				className="object-cover w-full h-full"
			/>
		)
	}

	return (
		<div className={`${className}  overflow-hidden`}>
			{id ? (
				<div
					className="player-wrapper relative"
					style={{ paddingTop: `${100 / calculateAspectRatio(aspectRatio)}%` }}
				>
					{image && (
						<ReactPlayer
							light={image ? <WistiaThumbnail /> : true}
							url={`https://home.wistia.com/medias/${id}`}
							config={{
								wistia: {
									playerId: id,
									options: {
										...options,
										controlsVisibleOnLoad: true,
										playButton: false,
										plugin: {
											...options?.plugin,
											videoThumbnail: {
												...options?.plugin?.videoThumbnail,
												hashedId: id
											}
										},
										popover: true,
										videoFoam: true
									}
								}
							}}
							className="react-player"
							controls={true}
							playing={true}
							width="100%"
							height="100%"
							playIcon={<></>}
							style={{ position: 'absolute', top: 0, left: 0 }}
							onReady={handleOnReady}
							onDuration={(duration) => {
								handleOnDuration(duration)
							}}
							onPlay={handleOnPlay}
							onPause={handleOnPause}
							onProgress={(data) => {
								handleOnProgress(data)
							}}
							onEnded={handleOnEnded}
						/>
					)}
				</div>
			) : (
				<div className="p-5 w-full text-center text-lg bg-yellow-300 rounded-lg">
					Cannot find ID of Wistia video. The Share URL should look like &nbsp;
					<code>https://fast.wistia.com/medias/:yourID</code>
				</div>
			)}
		</div>
	)
}
