
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { PropsWithChildren, useState } from 'react';
import { NextPage } from 'next';
import Head from 'next/head';
import { MakeswiftPageSnapshot } from '@makeswift/runtime/next';
import type { AppProps as NextAppProps } from 'next/app';
import { LiveChatLoaderProvider } from 'react-live-chat-loader';
import '@/styles/global.scss';
import { useBuildCookie } from '@/utils/analytics';
import { useReferralCookie } from '@/utils/cookies/useReferralCookie';
import { getSeoLocale } from '@/utils/locales/getSeoLocale';
import { useVercelEnvs } from '@/utils/url/useVercelEnvs';
import { DefaultSEO } from '@/components/SEO/DefaultSEO';
import { DriftWidget, Munchkin, OneTrust, SpeedInsightsLoadScript } from '@/components/Scripts';
import { ConsentProvider } from '@/components/Scripts/OneTrust/ConsentProvider';
import { AnalyticsProvider } from '@/components/Scripts/Segment/AnalyticsProvider';
export type PageProps = PropsWithChildren<{
    pageId: string | null;
    snapshot: MakeswiftPageSnapshot;
}>;
type AppProps = {
    Component: NextPage<PageProps>;
    pageProps: PageProps;
} & Omit<NextAppProps<PageProps>, 'pageProps'>;
const App = ({ Component, pageProps, router }: AppProps) => {
    useBuildCookie('bc_analytics', 2000, router.asPath);
    useBuildCookie('bc_analytics_session', 0, router.asPath);
    const [liveChatLoaded, setLiveChatLoaded] = useState<boolean>(false);
    let bcReferralValue = null;
    const isMakeSwiftBuilder = pageProps.snapshot?.preview;
    // Gets the value from the page pros ( base locale en-US )
    if (pageProps.snapshot?.document?.data && 'props' in pageProps.snapshot.document.data) {
        bcReferralValue = pageProps.snapshot.document.data?.props?.bcReferralCookie?.toString();
    }
    // Gets the value from the localized page ( translated pages i.e. es-ES )
    const localizedPage = pageProps.snapshot?.document.localizedPages.at(0);
    if (localizedPage?.data && 'props' in localizedPage?.data) {
        bcReferralValue = localizedPage.data?.props?.bcReferralCookie?.toString();
    }
    useReferralCookie({ bcReferralValue });
    useVercelEnvs();
    const handleLiveChatBeforeInit = () => {
        if (!window?.drift) {
            window.drift = window?.drift || [];
        }
        setLiveChatLoaded(true);
    };
    const handleLiveChatOnReady = () => {
        setLiveChatLoaded(true);
    };
    return (<>
			<Head>
				<script dangerouslySetInnerHTML={{
            __html: `/* __${process.env.NEXT_PUBLIC_VERCEL_ENV} LOCALE: ${getSeoLocale(router.locale)} */`
        }}/>
			</Head>
			<DefaultSEO locale={router.locale}/>
			<ConsentProvider>
				<AnalyticsProvider>
					<LiveChatLoaderProvider providerKey={`${process.env.NEXT_PUBLIC_DRIFT_KEY}`} provider="drift" beforeInit={handleLiveChatBeforeInit} onReady={handleLiveChatOnReady}>
						{!isMakeSwiftBuilder && <OneTrust />}
						<SpeedInsightsLoadScript />
						<Munchkin />
						<DriftWidget liveChatLoaded={liveChatLoaded}/>
						<Component {...pageProps}/>
					</LiveChatLoaderProvider>
				</AnalyticsProvider>
			</ConsentProvider>
		</>);
};
const __Next_Translate__Page__194758c5f2b__ = App;

    export default __appWithI18n(__Next_Translate__Page__194758c5f2b__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  