import dynamic from 'next/dynamic'

import { Color, List, Select, Shape, Slot, Style, TextInput } from '@makeswift/runtime/controls'
import { forwardNextDynamicRef } from '@makeswift/runtime/next'

import { runtime } from '@/lib/makeswift/runtime'

import { FolderStructure } from '@/utils/makeswift/constants'

runtime.registerComponent(
	forwardNextDynamicRef((patch) =>
		dynamic(() => patch(import('./StickyIndicator').then(({ StickyIndicator }) => StickyIndicator)))
	),
	{
		type: 'sticky-indicator',
		label: `${FolderStructure.UnderDevelopment}/Anchor Navigation`,
		props: {
			className: Style({ properties: [Style.Width, Style.Margin] }),
			border: Style({ properties: [Style.Border] }),
			bgColor: Color({ label: 'Background Color', defaultValue: '#EDEFF3' }),
			theme: Select({
				label: 'Theme',
				options: [
					{ label: 'Light', value: 'light' },
					{ label: 'Dark', value: 'dark' }
				],
				defaultValue: 'light'
			}),
			anchorTitle: TextInput({
				label: 'Anchor Title',
				defaultValue: 'Anchor Title'
			}),
			anchors: List({
				label: 'Anchor links',
				type: Shape({
					type: {
						id: TextInput({ label: 'Anchor id', defaultValue: 'anchor-1' }),
						title: TextInput({ label: 'Anchor link', defaultValue: 'Anchor link' }),
						children: Slot()
					}
				}),
				getItemLabel() {
					return 'Anchor links'
				}
			})
		}
	}
)
