// API Reference documentation https://developers.marketo.com/javascript-api/forms/api-reference/
import { useCallback, useEffect, useState } from 'react'

import { useRouter } from 'next/router'
import Script from 'next/script'

import * as Sentry from '@sentry/nextjs'
import clsx from 'clsx'

import { MarketoFormRemoveEmptyFields } from '@/utils/helpers/Array'
import { useUrl } from '@/utils/url/useUrl'

import { useAnalyticsContext } from '@/components/Scripts/Segment/AnalyticsProvider'

import { EHawkTalonLoadScript } from '../Scripts/Talon'

const marketoInstanceId = process.env.NEXT_PUBLIC_MARKETO_INSTANCE_ID
const marketoDomain = process.env.NEXT_PUBLIC_MARKETO_DOMAIN

declare const window: Window &
	typeof globalThis & {
		MktoForms2: MktoForms2
	}

type FormObjectProps = {
	onValidate(p: object): void
	onSuccess: (p: object) => void
	onSubmit: (p: object) => void
	submittable: (p: boolean) => void
	setValues: (arg0: Record<string, string>) => void
	getFormElem: () => {
		0: HTMLFormElement
		context: Document
		length: number
		selector: string
	}
}

interface MktoForms2 {
	getForm(formId: string): any
	loadForm: (
		arg1: string,
		arg2: string,
		arg3: string,
		Callback: (arg0: FormObjectProps) => void
	) => void
	whenReady: (Callback: (arg0: FormObjectProps) => void) => void
}

type MarketoFormParams = {
	className?: string
	formId?: string
	isModal?: boolean
	onSuccessCallback?: () => void
	postPdfUrl?: string | null
	postTitle?: string | null
	postUrl?: string | null
	submitText: string
	colorMode: 'light' | 'dark'
	style: 'default' | 'underlined'
	size: 'default' | 'large'
	color: 'primary' | 'secondary'
	align: 'left' | 'center' | 'right'
}

export const MarketoForm = ({
	formId = '',
	submitText = 'submit',
	className,
	postPdfUrl = '',
	postUrl = '',
	postTitle = '',
	colorMode = 'light',
	style = 'underlined',
	size = 'large',
	color = 'primary',
	align = 'left',
	onSuccessCallback
}: MarketoFormParams) => {
	const [form, setForm] = useState<FormObjectProps | null>(null)
	const idFormFull = `mktoForm_${formId}`
	const [ready, setReady] = useState(false)
	const [loadingState, setLoadingState] = useState('loading')
	const url = useUrl()?.href
	const { analytics } = useAnalyticsContext()
	const { asPath, query, pathname } = useRouter()
	const isBlogPage = pathname.includes('blog')
	const [talon, setTalon] = useState('{"version": 6, "status": -1}')

	const addEffects = useCallback(() => {
		if (!form) return

		const element = form.getFormElem()[0]

		const containers = Array.from(element.getElementsByClassName('mktoFieldWrap'))
		const inputs = Array.from(element.getElementsByTagName('input'))
		const selects = Array.from(element.getElementsByTagName('select'))
		const recaptchaText = element.getElementsByClassName('mktoCaptchaDisclaimer')
		const buttons = element.getElementsByClassName('mktoButtonRow')
		if (buttons?.length > 0) buttons[0].classList.add('!pt-6')
		// all <a></a> tags to be styled as text-bc-blue and hover:underline
		if (recaptchaText.length > 0) {
			const recaptchaLinks = Array.from(recaptchaText[0].getElementsByTagName('a'))

			if (isBlogPage) recaptchaText[0].classList.add('text-white', '!text-center')

			recaptchaLinks.forEach((link) => {
				link.classList.add(`${isBlogPage ? 'text-bc-green' : 'text-bc-blue'}`, 'hover:underline')
			})
		}

		// add interactive class to animate labels and adding special classes to containers on marketo form
		containers.forEach((container) => {
			const mktoCheckboxList = Array.from(container.getElementsByClassName('mktoCheckboxList'))

			// if we have a checkboxList in the form we need to add the proper css classes to the container
			if (mktoCheckboxList.length > 0) {
				container.className = 'mktoLogicalField mktoCheckboxList mktoHasWidth mktoRequired'
			}

			container.classList.add('interactive')
		})

		const onFocus = (ev: Event) => {
			ev.preventDefault()

			if (!(ev.target instanceof Element)) {
				return
			}
			const target = ev.target as HTMLInputElement

			if (target?.parentElement) {
				target.parentElement?.classList.add('notEmpty')
			}
		}

		const onBlur = (ev: Event) => {
			ev.preventDefault()

			if (!(ev.target instanceof Element)) {
				return
			}
			const target = ev.target as HTMLInputElement

			if (!target.value) {
				target.parentElement?.classList.remove('notEmpty')
			}
		}

		const onChange = (ev: Event) => {
			ev.preventDefault()

			if (!(ev.target instanceof Element)) {
				return
			}
			const target = ev.target as HTMLSelectElement

			if (target.options.selectedIndex) {
				target.parentElement?.classList.add('notEmpty')
			} else {
				target.parentElement?.classList.remove('notEmpty')
			}

			addEffects()
		}

		inputs.forEach((input) => {
			input.addEventListener('focus', onFocus)
			input.addEventListener('blur', onBlur)

			if (input.name === 'blogPostURL') {
				input.value = postUrl ?? ''
			} else if (input.name === 'blogPostTitle') {
				input.value = postTitle ?? ''
			} else if (input.name === 'blogPostPDFURL') {
				input.value = postPdfUrl ?? ''
			}
		})

		selects.forEach((select) => {
			select.addEventListener('change', onChange)
		})

		// Remove events on unmount
		return () => {
			inputs.forEach((input) => {
				input.removeEventListener('focus', onFocus)
				input.removeEventListener('blur', onBlur)
			})

			selects.forEach((select) => {
				select.removeEventListener('change', onChange)
			})
		}
	}, [form, isBlogPage, postPdfUrl, postTitle, postUrl])

	useEffect(() => {
		// Call the addEffects function, which ensures proper styling of the form
		if (form) addEffects()
	}, [addEffects, form])

	useEffect(() => {
		if (!form) return

		const element = form.getFormElem()[0]
		const buttons = element.getElementsByClassName('mktoButton')

		if (buttons.length) {
			const mktButton = buttons[0] as HTMLButtonElement

			mktButton.innerText = submitText
		}
	}, [form, submitText])

	useEffect(() => {
		if (!form) return

		form.onValidate((isValid: boolean) => {
			if (!isValid) {
				analytics?.track('Web Error Experienced', {
					page_url: `${url}${asPath}`,
					page_path: asPath,
					page_title: document.title,
					page_name: document.title,
					page_referrer: document.referrer,
					page_search: window.location.search,
					error_type: 'Form Validation',
					error_message: `Validation Error occured when submitting Marketo form: ${formId}`,
					error_label: `Error occured on Marketo form: ${formId}`
				})
			}
		})
	}, [analytics, asPath, form, formId, query, url])

	useEffect(() => {
		if (!form) return

		form.onSuccess((formValues: { [fieldName: string]: unknown }) => {
			const form = { ...formValues }
			const inputAcceptList = [
				'_mktoReferrer',
				'checksum',
				'checksumFields',
				'Company',
				'Country',
				'formid',
				'formSetLeadSourceManually',
				'formVid',
				'gaClientID__c',
				'GCLID__c',
				'honeypot',
				'MBA_Referral_ID__c',
				'MKT_UTM_Campaign__c',
				'MKT_UTM_Medium__c',
				'MKT_UTM_Source__c',
				'mKTUTMKeyword',
				'munchkinId',
				'Projected_Annual_Revenue__c',
				'Title',
				'Website'
			]

			Object.keys(form).forEach((fieldName) => {
				if (!inputAcceptList.includes(fieldName)) {
					delete form[fieldName]
				}
			})

			Sentry.addBreadcrumb({
				category: 'marketo',
				message: 'Form Submitted',
				data: form,
				level: 'info'
			})

			if (onSuccessCallback) {
				onSuccessCallback()

				return false
			}
		})

		window.MktoForms2.whenReady(() => {
			addEffects()
			setLoadingState('loaded')
		})
	}, [addEffects, form, onSuccessCallback])

	useEffect(() => {
		if (!ready) return

		if (!marketoInstanceId) {
			console.error('Marketo instance ID is not defined')
		} else if (!marketoDomain) {
			console.error('Marketo domain is not defined')
		} else {
			try {
				window.MktoForms2.loadForm(
					`https://${marketoDomain}`,
					marketoInstanceId,
					formId,
					(form: FormObjectProps) => {
						console.log('Marketo form loaded:', form)
						if (!form) setLoadingState('error')
						setForm(form)
					}
				)
			} catch (error) {
				console.error('Error loading Marketo form:', error)
			}
		}
	}, [ready, formId])

	useEffect(() => {
		if (!form) return

		const originalOnSubmit = form.onSubmit

		form.onSubmit(async (formValues: any) => {
			const formData = MarketoFormRemoveEmptyFields(formValues.getValues())

			try {
				const response = await fetch('/api/marketo-ehawk-request/', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json'
					},
					body: JSON.stringify({ ...formData, talon })
				})

				const isFraud = await response.json()
				analytics.identify({ riskScore: isFraud.risk })
				form.submittable(true)
				if (isFraud.pass) {
					originalOnSubmit(formValues)
				} else {
					console.error('Form submission blocked due to fraud risk.')
				}
			} catch (error) {
				console.error('Error during fraud check:', error)
				form.submittable(false)
			}
		})
	}, [form, analytics, talon])

	return (
		<div className={className}>
			<Script
				id="marketoScript"
				src={`https://${marketoDomain}/js/forms2/js/forms2.min.js`}
				strategy="afterInteractive"
				onReady={() => setReady(true)}
			/>

			<form
				key={formId}
				className={clsx(
					{ light: 'light', dark: 'dark' }[colorMode],
					{ default: 'default', underlined: 'underlined' }[style],
					{ default: 'submitDefault', large: 'submitLarge' }[size],
					{
						primary: 'submitBlue',
						secondary: `${colorMode === 'light' ? 'submitBlack' : 'submitWhite'}`
					}[color],
					{ left: 'submitLeft', center: 'submitCenter', right: 'submitRight' }[align],
					'mktoForm form min-h-[40px] w-full'
				)}
				data-formid={formId}
				data-forminstance={postPdfUrl ? 'blog-post-pdf' : undefined}
				id={idFormFull}
				data-testid="marketo-form"
			>
				{(!ready || loadingState !== 'loaded') && (
					<div
						className={`relative ${loadingState} ${
							loadingState !== 'loading' ? 'animate-none' : 'animate-pulse'
						}`}
					>
						{loadingState === 'error' ? (
							<div className="absolute block w-3/4 mx-auto top-1/4 left-0 right-0 bg-white p-4 shadow-md rounded-lg">
								<p className="text text-md text-gray-700 font-normal leading-6 m-0 p-0">
									We are currently undergoing maintenance and this form is unavailable. We apologize
									for the inconvenience. Please try again later.
								</p>
							</div>
						) : null}
						<div className="w-2/3 h-8 bg-gray-1300 rounded mb-2"></div>
						<div className="w-full h-8 bg-gray-1300 rounded mb-2"></div>
						<div className="w-full h-8 bg-gray-1300 rounded mb-2"></div>
						<div className="w-1/2 h-8 bg-gray-1300 rounded mb-2"></div>
						<div className="w-2/3 h-8 bg-gray-1300 rounded mb-2"></div>
						<div className="w-full h-8 bg-gray-1300 rounded mb-2"></div>
						<div className="w-full h-8 bg-gray-1300 rounded mb-2"></div>
						<div className="w-1/2 h-8 bg-gray-1300 rounded mb-2"></div>
						<div className="w-2/3 h-8 bg-gray-1300 rounded mb-2"></div>
					</div>
				)}
			</form>

			<input
				type="text"
				name="talon6"
				id="talon6"
				defaultValue={talon}
				style={{ display: 'none' }}
			/>
			<EHawkTalonLoadScript eHawkTalonLoaded={(res) => setTalon(res)} />
		</div>
	)
}
